import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('pages/NHome.vue')
      },
      {
        path: 'account',
        name: 'Account',
        component: () => import('../pages/NAccount.vue')
      },
      {
        path: 'notary-product/:id',
        name: 'NotaryProductDetail',
        component: () => import('../pages/NNotaryProductDetails.vue'),
        props: true
      },
      {
        path: 'variables/:id',
        name: 'NotaryProductVariables',
        component: () => import('../pages/NNotaryProductVariables.vue'),
        props: true
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import('../pages/NInternalNotes.vue')
      }
    ]
  },
  {
    path: '/token-auth',
    name: 'TokenAuth',
    component: () => import('pages/auth/NTokenAuth.vue'),
    meta: { noAuth: true },
    props: (route) => ({
      access_token: route.query.access_token,
      client: route.query.client,
      expiry: route.query.expiry,
      uid: route.query.uid
    })
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../pages/auth/NLogin.vue'),
    meta: { noAuth: true }
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    component: () => import('../pages/auth/NResetPassword.vue'),
    meta: { noAuth: true }
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('../pages/auth/NChangePassword.vue'),
    props: (route) => ({
      access_token: route.query['access-token'],
      client: route.query.client,
      expiry: route.query.expiry,
      uid: route.query.uid
    }),
    meta: { noAuth: true }
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    name: '404',
    component: () => import('../pages/NNotFound.vue')
  }
];

export default routes;
