const API = process.env.VITE_API;
export const BASE_API = `${API}api_notary_dashboard`;

export const RESOURCES = {
  LIGO: API,
  BASE: API,
  LOGIN: `${API}api/notary_auth/sign_in`,
  AUTH: `${API}api/notary_auth`,
  REGISTER: '#',
  LOGOUT: `${API}api/notary_auth/sign_out`,
  CONFIRMATION: '#',
  ME: `${BASE_API}/me`,
  CLEAN_PROFILE_IMAGE: `/${BASE_API}/me/clean_profile_image`,
  RESET_PASSWORD: '#',
  PASSWORD_CONFIRM: '#',
  NOTES: `${BASE_API}/notes`,
  NOTIFICATION: `${BASE_API}/notifications`,
  NOTARY_PRODUCT: `${BASE_API}/notary_products/`,
  NOTARY_PRODUCT_DOCUMENT: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/notary_product_documents`,
  NOTARY_PRODUCT_NOTES: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/notes`,
  NOTARY_PRODUCT_VARIABLES: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/variables`,
  NOTARY_PRODUCT_DOCUMENT_ACCEPT: (idNotaryProduct: string, idNotaryProductDocument: string): string =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}/accept`,
  NOTARY_PRODUCT_DOCUMENT_REJECT: (idNotaryProduct: string, idNotaryProductDocument: string): string =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}/reject`,
  NOTARY_PRODUCT_DOCUMENT_DELETE: (idNotaryProduct: string, idNotaryProductDocument: string): string =>
    `${BASE_API}/notary_products/${idNotaryProduct}/notary_product_documents/${idNotaryProductDocument}`,
  BULK_REQUEST: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/notary_product_documents/bulk`,
  NOTARY_PRODUCT_SEND_FCA: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/send_upload_fca_notification`,
  NOTARY_PRODUCT_COMMUNICATION_SUMMARY: (uuid: string): string =>
    `${BASE_API}/notary_products/${uuid}/communication_summary`,
  NOTARY_PRODUCT_RESUME_FILE: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/resume_file`,
  NOTARY_PRODUCT_TOGGLE_HOLD: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/toggle_hold`,
  NOTARY_PRODUCT_EXPORT_VARIABLES: (uuid: string): string => `${BASE_API}/notary_products/${uuid}/export_variables`,
  NOTARY_PRODUCT_REQUIRED_DOCUMENT: (uuid: string): string =>
    `${BASE_API}/notary_products/${uuid}/notary_product_documents/required_document`
};
