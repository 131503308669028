import { buildQuery, createGetService } from './service-factory';
import { ApiService } from './api.service';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  NotaryProduct,
  NotaryProductDocument,
  NotaryProductEvent,
  NotaryProductStage,
  NotaryProductTypeSlug,
  Dictionary
} from '../models';
import { RESOURCES } from './resources';

export const NotaryProductService = {
  ...createGetService<NotaryProduct>(RESOURCES.NOTARY_PRODUCT, ApiService),

  list(
    query: Dictionary = {},
    stages?: NotaryProductStage[],
    notary_product_types?: NotaryProductTypeSlug[]
  ): AxiosPromise<NotaryProduct[]> {
    return ApiService.get<NotaryProduct[]>(RESOURCES.NOTARY_PRODUCT + buildQuery(query), {
      stages,
      notary_product_types
    });
  },

  changeStage(uuid: string, event: NotaryProductEvent): AxiosPromise<NotaryProduct> {
    return ApiService.patch(`${RESOURCES.NOTARY_PRODUCT}/${uuid}`, { event });
  },

  async sendFca(uuid: string): Promise<void> {
    const response = await ApiService.patch(RESOURCES.NOTARY_PRODUCT_SEND_FCA(uuid), {});
    console.log(response);
  },

  addDocument(uuid: string, docId: string | undefined, data: FormData): AxiosPromise {
    if (docId) return ApiService.patch(`${RESOURCES.NOTARY_PRODUCT_DOCUMENT(uuid)}/${docId}`, data, true);
    return ApiService.post(RESOURCES.NOTARY_PRODUCT_DOCUMENT(uuid), data, true);
  },

  async requestUploads(uuid: string, names: string[], message: string): Promise<void> {
    await ApiService.post(RESOURCES.BULK_REQUEST(uuid), { names, message });
  },

  async acceptDocument(uuid: string, docId: string): Promise<void> {
    await ApiService.patch(RESOURCES.NOTARY_PRODUCT_DOCUMENT_ACCEPT(uuid, docId), {});
  },

  async rejectDocument(uuid: string, docId: string, data: Dictionary): Promise<void> {
    await ApiService.patch(RESOURCES.NOTARY_PRODUCT_DOCUMENT_REJECT(uuid, docId), data);
  },

  async listDocuments(uuid: string, query: Dictionary): Promise<AxiosResponse<NotaryProductDocument[]>> {
    return await ApiService.get<NotaryProductDocument[]>(RESOURCES.NOTARY_PRODUCT_DOCUMENT(uuid), query);
  },

  async deleteDocument(uuid: string, docId: string): Promise<void> {
    await ApiService.delete(RESOURCES.NOTARY_PRODUCT_DOCUMENT_DELETE(uuid, docId));
  },

  getVariables(uuid: string): AxiosPromise<Dictionary<Dictionary<string>>> {
    return ApiService.get(RESOURCES.NOTARY_PRODUCT_VARIABLES(uuid));
  },

  toggleOnHold(uuid: string, onHoldReason?: string, onHoldDescription?: string): AxiosPromise<void> {
    return ApiService.patch(RESOURCES.NOTARY_PRODUCT_TOGGLE_HOLD(uuid), {
      on_hold_reason: onHoldReason,
      on_hold_description: onHoldDescription
    });
  },

  changeAlias(uuid: string, newAlias: string) {
    return ApiService.patch(`${RESOURCES.NOTARY_PRODUCT}/${uuid}`, {
      alias: newAlias
    });
  },

  async requestDocument(uuid: string, name: string, file: any, message: string): Promise<void> {
    const formData = new FormData();
    formData.append('name', name);
    if (file) formData.append('file', file);
    if (message) formData.append('message', message);
    await ApiService.post(RESOURCES.NOTARY_PRODUCT_REQUIRED_DOCUMENT(uuid), formData);
  }
};

export const inProgressStages = [
  NotaryProductStage.CHECKING_DOCUMENTS,
  NotaryProductStage.UPLOADING_FCA,
  NotaryProductStage.EXECUTING_DEED,
  NotaryProductStage.SCHEDULING_MEETING,
  NotaryProductStage.EXTRACTING_KVK
];
