import routes from './routes';
import { createRouter, createWebHistory } from 'vue-router';
import { STORAGE_KEY } from 'src/services';

const router = createRouter({
  routes,
  history: createWebHistory()
});

router.beforeEach(async (to, from, next) => {
  // If the next route requires auth and there is no token, send to login
  if (localStorage.getItem(STORAGE_KEY) == null && !to.meta?.noAuth) {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
