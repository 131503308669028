import axios from 'axios';
import router from '../router';
import { Dictionary } from '../models';
import { RESOURCES } from './resources';
import { TokenInterface } from '../models';

export const STORAGE_KEY = 'auth-store';

const FORM_DATA_HEADER = { 'Content-Type': 'multipart/form-data' };

export const ApiService = {
  _401interceptor: 0,

  init(headers: TokenInterface): void {
    axios.defaults.baseURL = RESOURCES.BASE;
    if (headers && headers['access-token']) {
      this.setAuth(headers);
      this.mount401Interceptor();
    }
  },

  setAuth(headers: TokenInterface): void {
    if (headers) {
      axios.defaults.headers.common['client'] = headers.client;
      axios.defaults.headers.common['uid'] = headers.uid;
      axios.defaults.headers.common['expiry'] = headers.expiry;
      axios.defaults.headers.common['access-token'] = headers['access-token'];
    }
  },

  cleanHeaders(): void {
    axios.defaults.headers.common = {};
  },

  get<R>(resource: string, params?: Dictionary, options?: Dictionary) {
    return axios.get<R>(resource, {
      params,
      ...options
    });
  },

  post<T, R = T>(resource: string, data?: T, formData = false) {
    return axios.post<R>(resource, data, {
      headers: this.extraHeaders(formData)
    });
  },

  auth_redirection<T, R = T>(url: string) {
    return axios.post<R>(url, undefined, { withCredentials: true });
  },

  put<T, R = T>(resource: string, data: T, formData = false) {
    return axios.put<R>(resource, data, {
      headers: this.extraHeaders(formData)
    });
  },

  patch<T, R = T>(resource: string, data: T, formData = false) {
    return axios.patch<R>(resource, data, {
      headers: this.extraHeaders(formData)
    });
  },

  delete(resource: string, data = {}) {
    return axios.delete(resource, {
      params: {
        ...data
      }
    });
  },

  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },

  extraHeaders(formData: boolean) {
    return { ...(formData && FORM_DATA_HEADER) };
  },

  clean() {
    this.cleanHeaders();
    this.unmount401Interceptor();
    localStorage.removeItem(STORAGE_KEY);
  },

  mount401Interceptor(): void {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.request.status == 401) {
          this.clean();
          if (router.currentRoute.value.name != 'Login') {
            router.push({
              name: 'Login',
              query: { redirectUrl: router.currentRoute.value.path }
            });
          } else {
            throw error;
          }
        } else {
          throw error;
        }
      }
    );
  }
};
